import { Routes, Route } from "react-router-dom";

import Header from "./components/header/header.component";

import Home from "./routes/home/home.component";

function App() {
  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
