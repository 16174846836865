import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header id="header" className="p-4 pt-2 md:pt-0 xl:p-8 xl:pt-0 md:fixed">
      <h1>
        <Link className="" to="/">
          <div className="inline text-4xl font-bold md:text-6xl xl:text-8xl">
            Gloria Yanas
          </div>
          <br />
          <div className="inline text-2xl md:text-3xl  xl:text-4xl italic">
            Medical Massage
          </div>
        </Link>
      </h1>
    </header>
  );
};

export default Header;
