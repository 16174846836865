import useWindowSize from "../../hooks/useWindowSize";

import "./home.styles.scss";

const MD_BREAKPOINT = 768;

const Home = () => {
  const { width: windowWidth } = useWindowSize();

  return (
    <div className="home-container px-4 mt-4 relative md:ml-[28rem] lg:ml-[34rem] xl:ml-[42rem] 2xl:ml-[45rem] flex flex-col items-center">
      <img
        id="body-img"
        className="home__body-image absolute md:fixed top-52 max-w-[25rem] md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl z-[-1] md:-left-[6rem] lg:-left-[6rem] xl:-left-[4rem] 2xl:left-0 md:top-32 xl:top-48"
        src="/assets/images/body.svg"
        alt="human body"
      />
      <div
        className={`${
          windowWidth > MD_BREAKPOINT && "animate__animated animate__fadeInUp"
        } md:mt-52 xl:mt-[19rem] sm:text-lg md:text-xl xl:text-3xl z-10 w-full md:max-w-[25rem] xl:max-w-[36rem] 2xl:max-w-[40rem] md:px-8`}
      >
        <section>
          <p className="max-w-72 sm:max-w-96 md:max-w-96 lg:max-w-[28rem] xl:max-w-[32rem] 2xl:max-w-[40rem] mb-[25rem] md:mb-16">
            Gloria is a skilled massage therapist based in Atlanta, GA.{" "}
          </p>

          <div className="flex sm:justify-center sm:items-center md:justify-start md:items-start w-full">
            <button className="cta-button font-bold bg-burnt-sienna transition w-full min-h-12 xl:min-h-16 rounded-full sm:max-w-96 md:w-full lg:max-w-[25rem] xl:max-w-[30rem] 2xl:max-w-[38rem]">
              Book Now
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
